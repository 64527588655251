const $ = require('jquery');
const IScroll = require('iscroll');
import 'jquery-drawer';
import 'jquery-drawer/dist/css/drawer.min.css';
import 'slick-carousel'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rellax';
const SmoothScroll = require('smooth-scroll');
import 'core-js/stable';
import 'regenerator-runtime/runtime';

$(function () {
  /**
   * スライダー
   */
  $('.slider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false
  });
  $('.slider').on('touchend touchcancel touchmove', function () { $(this).slick('slickPlay'); });
  /**
   * スクロールを開始するとヘッダーの高さを狭くする
   */
  $(window).on('scroll', function () {
    const scroll = $(this).scrollTop();
    const gnav = $('.gnav');
    let gnavPos = gnav.offset().top;
    if (scroll > 0) {
      $('.hd').addClass('hd--thin');
      $('.pagetop').addClass('pagetop--visible');
    } else {
      $('.hd').removeClass('hd--thin');
      $('.pagetop').removeClass('pagetop--visible');
    }
    if (gnavPos - scroll == 60) {
      gnav.addClass('is-sticky');
    } else {
      gnav.removeClass('is-sticky');
    }
  });
  /**
   * SmoothScroll
   */
  const scroll = new SmoothScroll('a[href*="#"]', {
    header: '[data-scroll-header]',
    updateURL: false
  });
  $(window).on('scroll', function () {
    let scroll = $(this).scrollTop();
    const footerPos = $('.ft-copyright').offset().top;
    const fixedDistance = 100;
    const btnHeight = 50;
    const offset = fixedDistance - btnHeight;
    const h = window.innerHeight;
    if (scroll == 0) {
      $('.pagetop').addClass('is-hidden');
    } else {
      $('.pagetop').removeClass('is-hidden');
    }
    if (footerPos <= scroll + h + offset) {
      $('.pagetop').addClass('is-fixed');
      $('.pagetop').css('bottom', fixedDistance);
    } else {
      $('.pagetop').removeClass('is-fixed');
      $('.pagetop').css('bottom', '5px');
    }
  });
  /**
   * Accordion menu
   */
  $('.dropdown').on('click', function () {
    $(this).toggleClass('dropdown--open');
  })
});

$(window).on('load resize', function () {
  const sp = window.matchMedia('screen and (max-width: 768px)').matches;
  if (sp) {
    $('.gnav').addClass('drawer-nav');
    $('.gnav > ul').addClass('drawer-menu');
    $('.drawer').drawer();
  } else {
    $('.gnav').removeClass('drawer-nav');
    $('.gnav > ul').removeClass('drawer-menu');
  }
});


 /**
   * recruit/production factory choice
*/
function factoryList() {
  const factories = document.querySelectorAll(".factory__list-item");
  const osaka = document.querySelector(".osaka");
  const fukui = document.querySelector(".fukui");
  const gifu = document.querySelector(".gifu");


  factories.forEach(function(elt) {
    elt.addEventListener("click", function() {

      factories.forEach(function(factory) {
        factory.classList.remove("factory__list-item--current");
      })
      elt.classList.add("factory__list-item--current");
      if (elt.classList.contains("osaka-factory")){
        gifu.classList.remove("current-factory")
        fukui.classList.remove("current-factory")
        osaka.classList.add("current-factory")
      }
      if (elt.classList.contains("fukui-factory")){
        gifu.classList.remove("current-factory")
        osaka.classList.remove("current-factory")
        fukui.classList.add("current-factory")
      }
      if (elt.classList.contains("gifu-factory")){
        osaka.classList.remove("current-factory")
        fukui.classList.remove("current-factory")
        gifu.classList.add("current-factory")
      }
    })
  })
}
factoryList();

// Also can pass in optional settings block
var rellax = new Rellax('.rellax');

/**
 * Splash animation
 */
$(function () {

  if (sessionStorage.getItem('visited')) {
    $('.splash').addClass('ended');
    return false;
  }

  setTimeout(function() {
    $('.splash').addClass('animated');
  }, 100);

  $('.splash__logo').on('transitionend', function() {
    setTimeout(function() {
      $('.splash').addClass('ended');
      sessionStorage.setItem('visited', 'true');
    }, 3000);
  })

});

